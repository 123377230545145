/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Chip, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Popover, PopoverContent, PopoverTrigger, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, User } from '@nextui-org/react';
import { IconChecklist, IconDownload, IconEye, IconPrinter, IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, autoFormatAmount, fromPhotos, fromStorage } from '../../../utils';
import { PaymentsService } from '../../../services';
import PopStatus from './popover-status';

const PM = Constants.PAYMENT.METHOD;

const MODAL_ACTION = {
  NONE: 0,
  VALIDATE: 1,
}

const paymentStatuses = [
  { value: Constants.PAYMENT.STATUS.PENDING.toString(), label: 'Por validar' },
  { value: Constants.PAYMENT.STATUS.APPROVED.toString(), label: 'Aprobado' },
  { value: Constants.PAYMENT.STATUS.REJECTED.toString(), label: 'Rechazado' },
];

function getStatusColor(status) {
  switch (status) {
    case Constants.PAYMENT.STATUS.PENDING:
      return 'warning';
    case Constants.PAYMENT.STATUS.APPROVED:
      return 'success';
    case Constants.PAYMENT.STATUS.REJECTED:
    default:
      return 'default';
  }
}

function getProductsNames(purchase, purchaseProductId = null) {
  if (purchaseProductId)
    return purchase?.purchase_products?.find(x => x.id === purchaseProductId)?.product?.name || '';

  const names = purchase?.purchase_products?.map(x => x.product?.name);
  const uniqueNames = [...new Set(names || [])];
  return uniqueNames.join(', ');
}

const Payments = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, filterBy, goToPage, isLoading, pagination, print, printItem, reload, validateItem } = useFetchTable();

  const onSelectItem = (item, action) => {
    setSelectedItem(item);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.VALIDATE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalValidate
              onClose={onClose}
              payment={selectedItem}
              onValidate={status => {
                validateItem(selectedItem?.id, status);
                closeModal();
              }}
            />
          )}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Pagos registrados"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4 sticky top-0 left-0">
            <h3 className="text-xl font-medium text-primary">Pagos registrados</h3>
            <Button variant="bordered" onClick={() => print()}>
              Exportar
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>Nombre del usuario</TableColumn>
          <TableColumn>Cédula</TableColumn>
          <TableColumn>Producto o servicio</TableColumn>
          <TableColumn>Fecha de pago</TableColumn>
          <TableColumn>Hora de operación</TableColumn>
          <TableColumn>Monto pagado</TableColumn>
          <TableColumn>Método de pago</TableColumn>
          <TableColumn>Banco de origen</TableColumn>
          <TableColumn>Nombre pagador</TableColumn>
          <TableColumn>N° de referencia o tarjeta</TableColumn>
          <TableColumn>N° de cuota</TableColumn>
          <TableColumn>Fecha de corte</TableColumn>
          <TableColumn>Canales de comercialización</TableColumn>
          <TableColumn>Estatus</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(payment) => {
            // const isPurchase = !payment.purchase_product_id;
            const canValidatePayment = ![PM.MOBILE, PM.ZELLE, PM.TRANSFER].includes(payment.payment_method_id);
            const statusColor = getStatusColor(payment.status);
            const productNames = getProductsNames(payment?.purchase, payment.purchase_product_id);
            const amount = autoFormatAmount(payment.amount, payment.payment_method?.currency, payment.conversion);
            const lastDate = moment.min(payment.purchase.purchase_products.map(x => moment(x.cutoff_at)));
            const showProducts = payment.purchase.purchase_products.filter(p => !!payment.purchase_product_id ? payment.purchase_product_id === p.id : true)
            // Para ocultar el popover mediante el dom
            const popStatId = `pop-stat-${payment.id}`;

            return (
              <TableRow key={payment.id}>
                <TableCell>{payment?.user?.fullName}</TableCell>
                <TableCell>{payment?.user?.dni}</TableCell>
                <TableCell>{productNames}</TableCell>
                <TableCell>{moment(payment?.date).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{moment(payment?.created_at).format('h:mmA')}</TableCell>
                <TableCell style={{ textWrap: 'nowrap' }}>{amount}</TableCell>
                <TableCell>{payment?.payment_method?.name}</TableCell>
                <TableCell>{payment?.bank?.name ?? '-'}</TableCell>
                <TableCell>{payment?.name ?? payment?.user?.fullName ?? '-'}</TableCell>
                <TableCell>{payment.reference}</TableCell>
                <TableCell>
                  {!!payment.cutoff_at ? `${payment.current_fee + payment.fees_qty - 1} de ${payment?.total_fees}` : '-'}
                </TableCell>
                <TableCell>
                  {!!payment.cutoff_at ? lastDate.format('DD/MM/YYYY') : '-'}
                </TableCell>
                <TableCell>
                  {payment.user?.referral_user?.marketing_type_text}
                </TableCell>
                <TableCell>
                  <Popover placement="bottom" showArrow backdrop="opaque">
                    <PopoverTrigger>
                      <Chip id={popStatId} color={statusColor} variant="flat" className="cursor-pointer">
                        {payment.status_text}
                      </Chip>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopStatus
                        item={payment}
                        onSubmit={(form) => {
                          validateItem(payment.id, form.status);
                          document.getElementById(popStatId)?.click();
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                </TableCell>
                <TableCell align="right">
                  <div className="relative flex justify-end items-center gap-2">
                    <Popover placement="left" showArrow offset={10}>
                      <PopoverTrigger>
                        <IconEye />
                      </PopoverTrigger>
                      <PopoverContent className="w-80">
                        {(titleProps) => (
                          <div className="px-1 py-2 w-full">
                            <p className="text-small font-bold text-foreground" {...titleProps}>
                              Detalles del pago
                            </p>
                            {showProducts.map(x => (
                              <div className="mt-2 pt-2 flex flex-col w-full border-t">
                                <p className="mb-0">
                                  <span className="font-semibold">Beneficiario: </span>
                                  <span>{x?.user?.fullName}</span>
                                </p>
                                <p className="mb-0">
                                  <span className="font-semibold">Producto: </span>
                                  <span>{x?.product?.name}</span>
                                </p>
                                <p className="mb-0">
                                  <span className="font-semibold">Número de cuota: </span>
                                  <span>{payment.current_fee + payment.fees_qty - 1}/{x.fees}</span>
                                </p>
                                <p className="mb-0">
                                  <span className="font-semibold">Fecha de corte: </span>
                                  <span>{moment(x.cutoff_at).format('DD/MM/YYYY')}</span>
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </PopoverContent>
                    </Popover>
                    {canValidatePayment && (
                      <Tooltip content="Validar">
                        <IconChecklist onClick={() => onSelectItem(payment, MODAL_ACTION.VALIDATE)} />
                      </Tooltip>
                    )}
                    <Tooltip content="Imprimir">
                      <IconPrinter onClick={() => printItem(payment.id)} />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    since: '',
    until: '',
    search: '',
    status: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Usuario, Producto o C.I."
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]' : 'foreground-400'}`,
        }}
        label="Fecha de pago (desde)"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]' : 'foreground-400'}`,
        }}
        label="Fecha de pago (hasta)"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      <Select
        label="Estatus del servicio"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="sd:max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[10rem]', trigger: 'border-1' }}
        disallowEmptySelection={true}
        selectedKeys={!!form.status ? [form.status] : []}
        onSelectionChange={v => onChange(v.currentKey, 'status')}
      >
        {paymentStatuses.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            {item.label}
          </SelectItem>
        ))}
      </Select>
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalValidate = ({ payment, onValidate }) => {

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = fromPhotos(payment.voucher);
    link.download = true;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Validación de Pago</ModalHeader>
      <ModalBody className="gap-0">
        {!!payment.bank && (
          <p><b>Banco emisor: </b>{payment?.bank?.name}</p>
        )}
        <p><b>Fecha de pago: </b>{moment(payment.date).format('DD/MM/YY')}</p>
        <p><b>Método de pago: </b>{payment.payment_method?.name}</p>
        <p><b>Monto pagado: </b>{autoFormatAmount(payment.amount, payment.payment_method?.currency, payment.conversion)}</p>
        {!!payment.reference && (
          <p><b>N° de referencia o tarjeta: </b>{payment.reference}</p>
        )}
        {!!payment.voucher && (
          <div className="flex items-center justify-between gap-x-4 mt-4">
            <User avatarProps={{ src: fromPhotos(payment.voucher), radius: 'sm', size: 'lg' }} name="Comprobante" />
            <div className="flex items-center gap-4">
              <Tooltip content="Descargar">
                <IconDownload className="cursor-pointer text-primary" onClick={downloadFile} />
              </Tooltip>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          className="flex-1"
          onPress={() => onValidate(Constants.PAYMENT.STATUS.REJECTED)}
        >
          Rechazar
        </Button>

        <Button
          color="success"
          className="text-white flex-1"
          onPress={() => onValidate(Constants.PAYMENT.STATUS.APPROVED)}
        >
          Aceptar
        </Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    search: '',
    status: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PaymentsService.admin.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const validateItem = async (id, status) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await PaymentsService.admin.update({ id, status });
      const action = status === Constants.PAYMENT.STATUS.APPROVED ? 'aprobado' : 'rechazado';
      toast.success(`Pago ${action} con éxito`);
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const printItem = async (id) => {
    setCanFetch(false);
    try {
      const res = await PaymentsService.admin.downloadItem(id);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = `Pago #${String(id).padStart(4, '0')}`;
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const res = await PaymentsService.admin.download(filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Pagos';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({
        ...filters,
        page: 1,
        [target]: value,
      });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    data,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    print,
    printItem,
    reload,
    validateItem,
  }
}

export default Payments;
