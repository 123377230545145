import { fetchWrapper, objectToQueryParams } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'general';

export const findPaymentFrequencies = async (filters) => {
  const url = `${baseUrl}/payment-frequencies?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const createConversion = async (form) => {
  const url = `${baseUrl}/conversion`;
  return await fetchWrapper.post(url, form);
}

export const findConversion = async (filters) => {
  const url = `${baseUrl}/conversion?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const getDocumentTypes = async () => {
  const url = `${baseUrl}/document-types`;
  return await fetchWrapper.get(url);
}

export const getGenders = async () => {
  const url = `${baseUrl}/genders`;
  return await fetchWrapper.get(url);
}

export const getPhoneCodes = async () => {
  const url = `${baseUrl}/phone-codes`;
  return await fetchWrapper.get(url);
}

export const getStates = async () => {
  const url = `${baseUrl}/states`;
  return await fetchWrapper.get(url);
}

export const getTownships = async (filters) => {
  const url = `${baseUrl}/townships?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const getParishes = async (filters) => {
  const url = `${baseUrl}/parishes?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const getPermissions = async () => {
  const url = `${baseUrl}/permissions`;
  return await fetchWrapper.get(url);
}


export const downloadQR = async (imageUrl, fileName, userName) => {
  try {
    if (!imageUrl) return;

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const img = new Image();

    img.src = URL.createObjectURL(blob);
    img.crossOrigin = "anonymous";

    img.onload = () => {
      const outerPadding = 100; // Espaciado exterior (blanco)
      const innerPadding = 80; // Espaciado interno (grid)
      const textHeight = 40; // Espacio para el texto dentro del inner padding
      const outerBgColor = "#ffffff"; // Color de fondo externo (blanco)
      const innerBgColor = "#e5e7eb"; // Color de fondo interno (gris)
      const textColor = "#0c33af"; // Color del texto
      const borderRadius = 30; // Borde redondeado para el innerPadding

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Definir tamaño total del canvas
      canvas.width = img.width + outerPadding * 2;
      canvas.height = img.height + outerPadding * 2 + textHeight;

      // Dibujar el fondo externo (blanco)
      ctx.fillStyle = outerBgColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Dibujar el fondo interno con bordes redondeados
      ctx.fillStyle = innerBgColor;
      ctx.beginPath();
      ctx.moveTo(outerPadding - innerPadding + borderRadius, outerPadding - innerPadding);
      ctx.lineTo(canvas.width - (outerPadding - innerPadding) - borderRadius, outerPadding - innerPadding);
      ctx.quadraticCurveTo(canvas.width - (outerPadding - innerPadding), outerPadding - innerPadding, canvas.width - (outerPadding - innerPadding), outerPadding - innerPadding + borderRadius);
      ctx.lineTo(canvas.width - (outerPadding - innerPadding), canvas.height - (outerPadding - innerPadding) - borderRadius);
      ctx.quadraticCurveTo(canvas.width - (outerPadding - innerPadding), canvas.height - (outerPadding - innerPadding), canvas.width - (outerPadding - innerPadding) - borderRadius, canvas.height - (outerPadding - innerPadding));
      ctx.lineTo(outerPadding - innerPadding + borderRadius, canvas.height - (outerPadding - innerPadding));
      ctx.quadraticCurveTo(outerPadding - innerPadding, canvas.height - (outerPadding - innerPadding), outerPadding - innerPadding, canvas.height - (outerPadding - innerPadding) - borderRadius);
      ctx.lineTo(outerPadding - innerPadding, outerPadding - innerPadding + borderRadius);
      ctx.quadraticCurveTo(outerPadding - innerPadding, outerPadding - innerPadding, outerPadding - innerPadding + borderRadius, outerPadding - innerPadding);
      ctx.closePath();
      ctx.fill();

      // Dibujar la imagen dentro del inner padding
      ctx.drawImage(img, outerPadding, outerPadding, img.width, img.height);

      // Agregar el texto dentro del innerPadding
      ctx.fillStyle = textColor;
      ctx.font = "45px Arial";
      ctx.textAlign = "center";
      ctx.fillText(userName, canvas.width / 2, canvas.height - outerPadding + 10);

      // Convertir el canvas en una imagen descargable
      const finalImageUrl = canvas.toDataURL("image/jpeg");

      const link = document.createElement("a");
      link.href = finalImageUrl;
      link.download = `${fileName}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(img.src);
    };
  } catch (error) {
    throw new Error("Error procesando la imagen");
  }
};